import "./style.scss";
import { Global } from "./common/global";
import { Server } from "./common/callServer";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
Global.source = urlParams.get("utm_source");

Global.serverObj = new Server();
Global.serverObj.send(
  Global.URL_CREATE,
  setUID.bind(this),
  null,
  {
    device: Global.isMobile ? "mobile" : "web",
    fresh: true,
    saveType: "create",
    source: Global.source,
  },
  "POST",
  null,
  false
);

function setUID(data) {
  console.log(data, "gaggh");
  let v = JSON.parse(data);
  console.log(v, "gaggh");
  Global.U_ID = v.uid;
  Global.gameKey = v.game_key;

  /* document.querySelector(".LanguageSelectionDiv").style.display = "block";
  document.querySelector(".lds-hourglass").style.display = "none"; */

  console.log(Global.U_ID, " gaggh ", Global.gameKey);
}

// document.addEventListener()

window.onload = function () {
  gtag("event", "conversion", {
    allow_custom_scripts: true,
    u1: "https://oreoarabia.com/",
    u21: Global.language,
    send_to: "DC-9227341/ae_or0/inter0+standard",
  });

  document.getElementById("nscript").innerHTML =
    '<img src="https://ad.doubleclick.net/ddm/activity/src=9227341;type=ae_or0;cat=inter0;u1=https://oreoarabia.com/;u21=' +
    Global.language +
    ';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>';

  document.querySelector(".languageSelDiv").addEventListener("click", () => {
    LanguageHandler();
  });

  document.body.addEventListener("click", () => {
    if (Global.firstTimeMusicPlayed === false) {
      document.getElementById("BgMUsic").play();
      document.getElementById("BgMUsic").loop = true;
      Global.firstTimeMusicPlayed = true;
    }
  });

  document.getElementById("HomeBtn").addEventListener("click", () => {
    Global.serverObj.send(
      Global.VALUE_UPDATER,
      null,
      null,
      {
        saveType: "homeBtn",
        uniqID: Global.U_ID,
        encryptkey: Global.gameKey,
      },
      "POST",
      null,
      false
    );
    // alert("Home");
    location.reload();
  });
  document.getElementById("BgMUsic").loop = true;
  /*  document.getElementById("BgMUsic").play();
  document.getElementById("BgMUsic").loop = true; */

  /*CHeckBox Handlers  */
  document.querySelector(".check1").addEventListener("click", () => {
    if (Global.checkBox1 === false) {
      Global.checkBox1 = true;
      Global.firstTimeMusicPlayed = true;
      document.querySelector(".firstPageErr").style.opacity = "0";
      document.querySelector(".check1").src =
        "https://www.newsfeedsmartapp.com/Oreo_IPL/assets/tick.png";
    } else {
      Global.checkBox1 = false;
      document.querySelector(".check1").src =
        "https://www.newsfeedsmartapp.com/Oreo_IPL/assets/box.png";
    }
  });
  document.querySelector(".check2").addEventListener("click", () => {
    if (Global.checkBox2 === "false") {
      Global.checkBox2 = "true";
      document.querySelector(".check2").src =
        "https://www.newsfeedsmartapp.com/Oreo_IPL/assets/tick.png";
    } else {
      Global.checkBox2 = "false";
      document.querySelector(".check2").src =
        "https://www.newsfeedsmartapp.com/Oreo_IPL/assets/box.png";
    }
  });

  /* SoundBtn Handler */
  document.querySelector(".soundImg").addEventListener("click", () => {
    if (Global.sound === true) {
      Global.sound = false;
      document.getElementById("BgMUsic").pause();
      document.querySelector(".soundImg").src =
        "https://www.newsfeedsmartapp.com/Oreo_IPL/assets/sound.png";
    } else {
      document.getElementById("BgMUsic").play();
      document.getElementById("BgMUsic").loop = true;
      Global.sound = true;
      document.querySelector(".soundImg").src =
        "https://www.newsfeedsmartapp.com/Oreo_IPL/assets/mute.png";
    }
  });

  /* Proceed Button Handler */

  document.querySelector(".proceedBtnDiv").addEventListener("click", () => {
    proceedBtnHandler();
  });

  triggerFirstPageAnimation();

  triggerThirdPageAnimation();

  // changeTranslations();

  /* Submit Button CLick Handler */
  document.querySelector(".SubmitBtnDiv").addEventListener("click", () => {
    submitBtnHandler();
  });

  document.getElementById("pc").addEventListener("click", () => {
    window.open("https://oreoarabia.com/privacy-policy/index.html");
  });
  document.getElementById("pcArab").addEventListener("click", () => {
    window.open("https://oreoarabia.com/privacy-policy/index.html");
  });

  /* TnCs text Click Handler */
  document.querySelector(".TnCBottomLeftTxt").addEventListener("click", () => {
    if (Global.country == "" || Global.country == null) {
      document.querySelector(".firstPageErr").style.opacity = "1";
    } else {
      document.querySelector(".firstPageDiv").style.display = "none";
      document.querySelector(".SecondPageDiv").style.display = "block";

      document.querySelector(".popupBG").style.display = "block";
      console.log(`popup${Global.country}_${Global.language}`);
      document.querySelector(
        `.popup${Global.country}_${Global.language}`
      ).style.display = "block";
      setTimeout(() => {
        document.querySelector(".popupBG").style.opacity = "1";
      }, 10);
    }
    /*  if (Global.country != "" || Global.country != null) {
      document.querySelector(".popupBG").style.display = "block";
      console.log(`popup${Global.country}_${Global.language}`);
      document.querySelector(
        `.popup${Global.country}_${Global.language}`
      ).style.display = "block";
      setTimeout(() => {
        document.querySelector(".popupBG").style.opacity = "1";
      }, 10);
    } else {
      
    } */
  });

  /* TnCs Click Handler */
  document.querySelector(".TnCCLick").addEventListener("click", () => {
    document.querySelector(".popupBG").style.display = "block";
    console.log(`popup${Global.country}_${Global.language}`);
    document.querySelector(
      `.popup${Global.country}_${Global.language}`
    ).style.display = "block";
    setTimeout(() => {
      document.querySelector(".popupBG").style.opacity = "1";
    }, 10);
  });
  /* TnCs Click Handler */
  document.querySelector(".TnCCLick2").addEventListener("click", () => {
    document.querySelector(".popupBG").style.display = "block";
    console.log(`popup${Global.country}_${Global.language}`);
    document.querySelector(
      `.popup${Global.country}_${Global.language}`
    ).style.display = "block";
    setTimeout(() => {
      document.querySelector(".popupBG").style.opacity = "1";
    }, 10);
  });

  /* Country Code Append Handler */
  const countryDropdown = document.getElementById("CountryCode");
  const cCode = document.querySelector(".cCode");
  // var selectedCountry = null;
  countryDropdown.addEventListener("change", function () {
    const selectedCountry = countryDropdown.value;
    // mobileInput.value = "";
    // Set the mobile number input value to the selected country
    cCode.innerHTML = selectedCountry;
    // console.log("Global.country", Global.country);
    if (countryDropdown.value === "+968") {
      Global.country = "Oman";
      document.querySelector(".firstPageErr").style.opacity = "0";
    } else if (countryDropdown.value === "+965") {
      Global.country = "Kuwait";
      document.querySelector(".firstPageErr").style.opacity = "0";
    } else if (countryDropdown.value === "+973") {
      Global.country = "Bahrain";
      document.querySelector(".firstPageErr").style.opacity = "0";
    } else if (countryDropdown.value === "+974") {
      Global.country = "Qatar";
      document.querySelector(".firstPageErr").style.opacity = "0";
    } else if (countryDropdown.value === "+971") {
      Global.country = "UAE";
      document.querySelector(".firstPageErr").style.opacity = "0";
    } else {
      Global.country = "";
      document.querySelector(".firstPageErr").style.opacity = "1";
    }
    console.log("Global.country", Global.country);
  });
};

function LanguageHandler() {
  document.querySelector(".glowImg").style.display = "none";
  clearInterval(firstPageTimeInterval);

  if (Global.language === "english") {
    Global.language = "arabic";
    Global.serverObj.send(
      Global.VALUE_UPDATER,
      null,
      null,
      {
        saveType: "arabicBtn",
        uniqID: Global.U_ID,
        encryptkey: Global.gameKey,
      },
      "POST",
      null,
      false
    );
  } else {
    Global.language = "english";
    Global.serverObj.send(
      Global.VALUE_UPDATER,
      null,
      null,
      {
        saveType: "englishBtn",
        uniqID: Global.U_ID,
        encryptkey: Global.gameKey,
      },
      "POST",
      null,
      false
    );
  }

  document.querySelector(
    ".languageImg"
  ).src = `https://www.newsfeedsmartapp.com/Oreo_IPL/assets/${Global.language}.png`;

  changeTranslations();

  triggerFirstPageAnimation();
}

/* TRANSLATION HANDLER */
function changeTranslations() {
  console.log("chnageeke");
  if (Global.language == "arabic") {
    // document.querySelector(".mainFormDiv").style.direction = "rtl";

    document.querySelector(".tempInstImg").src =
      "https://www.newsfeedsmartapp.com/Oreo_IPL/assets/instructions/arb_text.png";

    document.querySelector(".EngcheckBoxtext").style.display = "none";
    document.querySelector(".arabcheckBoxtext").style.display = "block";

    document.querySelector(".enterTxt").innerHTML = "أدخل التفاصيل الخاصة بك";
    document.getElementById("name").placeholder = "الاسم";
    document.getElementById("email").placeholder = "البريد الإلكتروني";
    // document.getElementById("CountryCode").placeholder = "البلد";

    document.querySelector(".TnCBottomLeftTxt").innerHTML =
      "*البنود و تطبق الشروط.";
    document.querySelector(".TnCBottomLeftTxt").style.fontFamily =
      "NotoSansArabic_Condensed_Medium";

    document.querySelector(".congratsImg").src =
      "https://www.newsfeedsmartapp.com/Oreo_IPL/assets/Vectorarabic.png";

    var countryArr = [
      "البلد",
      "سلطنة عمان",
      "الكويت",
      "البحرين",
      "دولة قطر",
      "الإمارات العربية المتحدة",
    ];
    for (let i = 0; i < 6; i++) {
      document.getElementById("CountryCode").options[i].text = countryArr[i];
    }

    document.querySelector(".CountryDiv").style.direction = "rtl";

    document.querySelector(".CountryDiv").style.fontFamily =
      "NotoSansArabic_Condensed_Medium";

    document.getElementById("CountryCode").style.fontFamily =
      "NotoSansArabic_Condensed_Medium";

    // document.querySelector(".mobileDiv").style.direction = "ltr";

    // console.log(document.getElementById("CountryCode").options[0].text, "OOOO");
    document.getElementById("mobile").placeholder = "رقم الهاتف";

    document.querySelector(".inputDiv").style.fontFamily =
      "NotoSansArabic_Condensed_Medium";
    document.querySelector(".findUniqueTxt").style.fontFamily =
      "NotoSansArabic_Condensed_Medium";

    document.querySelector(".checkboxDiv").style.direction = "rtl";
    // document.querySelector(".agreeTxt").firstChild.textContent = "أقبل";
    /* document.querySelector(".TnCCLick").firstChild.textContent =
      "الشروط والأحكام"; */
    /*  document.querySelector(".privacyTxt").firstChild.textContent =
      "إشعار الخصوصية";
    document.querySelector(".privacyTxt2").firstChild.textContent =
      "إشعار الخصوصية"; */
    /* document.querySelector(".ofTxt").firstChild.textContent =
      "لمونديليز والموافقة على استخدام Mondelez لبياناتي الشخصيةالمعلومات كماالمذكورة في الشروط والأحكام و"; */
    document.querySelector(".check2Txt").firstChild.textContent =
      "رغب في استلام رسائل ترويجية من موندليز حول منتجاتها وعروضها.";

    document.querySelector(".firstPageErr").firstChild.textContent =
      "يرجى اختيار بلدك";

    document.querySelector(".firstPageErr").style.fontFamily =
      "NotoSansArabic_Condensed_Medium";
    document.querySelector(".err").style.fontFamily =
      "NotoSansArabic_Condensed_Medium";

    document.querySelector(".checkboxDiv").style.fontFamily =
      "NotoSansArabic_Condensed_Medium";

    document.querySelector(".proceedTxt").firstChild.textContent = "يتابع";
    document.querySelector(".proceedTxt").style.fontFamily =
      "adobearabic_regular";
    document.querySelector(".SubmitTxt").firstChild.textContent = "إرسال";
    document.querySelector(".SubmitTxt").style.fontFamily =
      "adobearabic_regular";

    document.querySelector(".enterTxt").style.fontFamily =
      "NotoSansArabic_Condensed_ExtraBold";
    document.querySelector(".enterUniqueCodeTxt").style.fontFamily =
      "NotoSansArabic_Condensed_ExtraBold";

    document.querySelector(".enterUniqueCodeTxt").firstChild.textContent =
      "أدخل الرمز الفريد";
    document.querySelector(".findUniqueTxt").firstChild.textContent =
      "ابحث عن الرمز الفريد داخل الكم";

    document.querySelector(".lastPageBottomTxt").firstChild.textContent =
      "لقد قدمت بنجاح دخولك إلى";
    document.querySelector(".lastPageBottomTxt").style.fontFamily =
      "NotoSansArabic_Condensed_Medium";

    document.querySelector(".lastPageBottomTxtBold").firstChild.textContent =
      " اربح نسخة محدودة من الذهبمضرب*";
    document.querySelector(".lastPageBottomTxtBold").style.fontFamily =
      "NotoSansArabic_Condensed_ExtraBold";

    // console.log(document.querySelector(".ofTxt").firstChild);
  } else {
    document.querySelector(".tempInstImg").src =
      "https://www.newsfeedsmartapp.com/Oreo_IPL/assets/instructions/eng_text.png";
    document.querySelector(".congratsImg").src =
      "https://www.newsfeedsmartapp.com/Oreo_IPL/assets/congrats.png";
    document.querySelector(".EngcheckBoxtext").style.display = "block";
    document.querySelector(".arabcheckBoxtext").style.display = "none";

    /*  document.querySelector(".enterTxt").innerHTML =
      "Enter your details and unique number"; */
    document.querySelector(".checkboxDiv").style.direction = "ltr";
    document.querySelector(".enterTxt").innerHTML = "Enter your details";
    document.querySelector(".enterTxt").style.fontFamily = "PlutoCondMedium";

    document.querySelector(".TnCBottomLeftTxt").innerHTML =
      "*Terms & Conditions apply.";
    document.querySelector(".TnCBottomLeftTxt").style.fontFamily =
      "PlutoCondLight";

    document.getElementById("name").placeholder = "name";
    document.getElementById("email").placeholder = "email";

    document.querySelector(".CountryDiv").style.direction = "ltr";
    document.querySelector(".CountryDiv").style.fontFamily = "PlutoCondMedium";

    var EngCountryArr = [
      "Country",
      "Oman",
      "Kuwait",
      "Bahrain",
      "Qatar",
      "UAE",
    ];
    for (let i = 0; i < 6; i++) {
      document.getElementById("CountryCode").options[i].text = EngCountryArr[i];
    }

    document.getElementById("CountryCode").style.fontFamily = "PLUTOHEAVY";
    document.getElementById("mobile").placeholder = "mobile";

    document.querySelector(".inputDiv").style.fontFamily = "PlutoCondMedium";
    document.querySelector(".findUniqueTxt").style.fontFamily =
      "PlutoCondLight";

    // document.querySelector(".checkboxDiv").style.direction = "ltr";
    document.querySelector(".agreeTxt").firstChild.textContent = "I accept the";
    document.querySelector(".TnCCLick").firstChild.textContent = " T&C ";
    /* document.querySelector(".privacyTxt").firstChild.textContent =
      "Privacy Notice";
    document.querySelector(".privacyTxt2").firstChild.textContent =
      "Privacy Notice"; */
    // document.querySelector(".ofTxt").firstChild.textContent =
    //   "of Mondelez and consent to Mondelez using my personal information as stated in the T&Cs and";
    document.querySelector(".check2Txt").firstChild.textContent =
      "I would like to recieve promotional communication from Mondelez about its productsand offers by e-mail and SMS.";

    document.querySelector(".firstPageErr").firstChild.textContent =
      "Please select your country.";
    document.querySelector(".firstPageErr").style.fontFamily = "PlutoCondLight";
    document.querySelector(".err").style.fontFamily = "PlutoCondLight";

    document.querySelector(".checkboxDiv").style.fontFamily = "PlutoCondLight";
    document.querySelector(".proceedTxt").firstChild.textContent = "Proceed";
    document.querySelector(".proceedTxt").style.fontFamily = "PLUTOHEAVY";

    document.querySelector(".SubmitTxt").firstChild.textContent = "Submit";
    document.querySelector(".SubmitTxt").style.fontFamily = "PLUTOHEAVY";

    document.querySelector(".enterTxt").style.fontFamily = "PlutoCondMedium";
    document.querySelector(".enterUniqueCodeTxt").style.fontFamily =
      "PlutoCondMedium";

    document.querySelector(".enterUniqueCodeTxt").firstChild.textContent =
      "Enter the unique code";
    document.querySelector(".findUniqueTxt").firstChild.textContent =
      "Find the unique code inside the sleeve";
  }
}

function submitBtnHandler() {
  setTimeout(() => {
    document.getElementById("name").classList.remove("shakeClass");
    document.getElementById("email").classList.remove("shakeClass");
    document.getElementById("mobile").classList.remove("shakeClass");
    document.getElementById("uniqueCode").classList.remove("shakeClass");
    document.querySelector(".checkBox1").classList.remove("shakeClass");
    // document.getElementById("africanId").classList.remove("shakeClass");
    /* document.getElementById("dateofbirth").classList.remove("shakeClass");
    document.querySelector(".agreeTxt").classList.remove("shakeClass"); */
  }, 500);

  let nameVal = document.getElementById("name").value;
  let emailVal = document.getElementById("email").value;
  // let countryCodeVal = document.getElementById("CountryCode").value;
  let MobileVal = document.getElementById("mobile").value;
  let UniqueCodeValSmall = document.getElementById("uniqueCode").value;
  let UniqueCodeVal = UniqueCodeValSmall.toUpperCase();
  // let dateForDataBase = dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0];

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let tempData = null;
  var ga_cid = "1844306653.1689247851";
  ga_cid = getCookie2("_ga");

  if (typeof ga_cid === "undefined" || ga_cid === null) {
    ga_cid = "1844306653.1689247851";
  }
  /* if (Global.country == "UAE") {
    gtag("event", "conversion", {
      allow_custom_scripts: true,
      u21: Global.lang,
      send_to: "DC-9227341/oreo-000/conve0+standard",
    });

    document.getElementById("nscript").innerHTML =
      '<img src="https://ad.doubleclick.net/ddm/activity/src=9227341;type=oreo-000;cat=conve0;u21=' +
      Global.lang +
      ';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>';
  } */

  /* var ga_cid = 1844306653.1689247851 */ /* document.cookie.match(/_ga=(.+?);/)[1].split('.').slice(-2).join(".") */
  var utm_term = urlParams.get("utm_term");
  var utm_medium = urlParams.get("utm_medium");
  var utm_source = urlParams.get("utm_source");
  var utm_content = urlParams.get("utm_content");
  window.htk = "";
  window.htk = getCookie("hubspotutk");

  console.log(
    "nameVal--",
    nameVal,
    "emailVal--",
    emailVal,
    "MobileVal--",
    MobileVal,
    "UniqueCodeVal--",
    UniqueCodeVal
  );

  const mobile_regx = /^(\+\d{1,3}[- ]?)?\d{8,10}$/;
  var regex =
    /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  if (nameVal.length <= 0) {
    document.querySelector(".err").style.opacity = "1";
    if (Global.language == "english") {
      document.querySelector(".err").innerHTML = "Enter your name.";
    } else {
      document.querySelector(".err").innerHTML = "أدخل أسمك";
    }

    document.getElementById("name").classList.add("shakeClass");
    return;
  } else if (emailVal == "" || emailVal == null) {
    if (Global.language == "english") {
      document.querySelector(".err").innerHTML = "Please enter email id.";
    } else {
      document.querySelector(".err").innerHTML =
        "الرجاء إدخال معرف البريد الإلكتروني";
    }
    document.querySelector(".err").style.opacity = 1;
    document.getElementById("email").classList.add("shakeClass");
    return;
  } else if (!regex.test(emailVal)) {
    if (Global.language == "english") {
      document.querySelector(".err").innerHTML =
        "Please enter a valid email id.";
    } else {
      document.querySelector(".err").innerHTML =
        "الرجاء إدخال معرف بريد إلكتروني صالح";
    }
    document.querySelector(".err").style.opacity = 1;
    document.getElementById("email").classList.add("shakeClass");
    return;
  } else if (MobileVal == "" || MobileVal == null) {
    if (Global.language == "english") {
      document.querySelector(".err").innerHTML = "Please enter mobile no.";
    } else {
      document.querySelector(".err").innerHTML = "الرجاء إدخال رقم الجوال";
    }
    document.querySelector(".err").style.opacity = 1;
    document.getElementById("mobile").classList.add("shakeClass");

    return;
  } else if (!mobile_regx.test(MobileVal)) {
    if (Global.language == "english") {
      document.querySelector(".err").innerHTML =
        "Please enter a valid mobile no.";
    } else {
      document.querySelector(".err").innerHTML = "الرجاء إدخال رقم جوال صالح";
    }
    document.querySelector(".err").style.opacity = 1;
    document.getElementById("mobile").classList.add("shakeClass");

    return;
  } else if (UniqueCodeVal.length != 10) {
    if (Global.language == "english") {
      document.querySelector(".err").innerHTML =
        "Please enter correct Unique Code.";
    } else {
      document.querySelector(".err").innerHTML =
        "الرجاء إدخال الرمز الفريد الصحيح";
    }
    document.querySelector(".err").style.opacity = 1;
    document.getElementById("uniqueCode").classList.add("shakeClass");
    return;
  } else if (
    !(
      UniqueCodeVal.startsWith("UA24") ||
      UniqueCodeVal.startsWith("UA2V") ||
      UniqueCodeVal.startsWith("KW2V") ||
      UniqueCodeVal.startsWith("MA24") ||
      UniqueCodeVal.startsWith("MA2V") ||
      UniqueCodeVal.startsWith("BH24") ||
      UniqueCodeVal.startsWith("BH2V") ||
      UniqueCodeVal.startsWith("QT29") ||
      UniqueCodeVal.startsWith("QT58")
    )
  ) {
    if (Global.language == "english") {
      document.querySelector(".err").innerHTML = "Invalid Coupon Code";
    } else {
      document.querySelector(".err").innerHTML = "رقم قسيمه غير صالح";
    }
    document.querySelector(".err").style.opacity = 1;
    document.getElementById("uniqueCode").classList.add("shakeClass");
    return;
  } else if (!Global.checkBox1) {
    if (Global.language == "english") {
      document.querySelector(".err").innerHTML =
        "Please agree to the terms and conditions.";
    } else {
      document.querySelector(".err").innerHTML =
        "يرجى الموافقة على الشروط والأحكام";
    }
    document.querySelector(".err").style.opacity = 1;
    document.querySelector(".checkBox1").classList.add("shakeClass");
    return;
  } else {
    document.querySelector(".err").style.opacity = 0;
    let newMobile =
      document.querySelector(".cCode").innerHTML + " " + MobileVal;
    // console.log(newMobile, "newMobile");

    Global.serverObj.send(
      Global.WEBSERVICE,
      (data) => {
        let parseData = JSON.parse(data);
        console.log(parseData.coupon, "  data");
        fbq("track", "CompleteRegistration");
        ttq.track("CompleteRegistration");

        gtag("event", "conversion", {
          allow_custom_scripts: true,
          u1: "https://oreoarabia.com/",
          u21: Global.language,
          u23: Global.country,
          send_to: "DC-9227341/ae_or0/conve0+standard",
        });

        document.getElementById("nscript").innerHTML =
          '<img src="https://ad.doubleclick.net/ddm/activity/src=9227341;type=ae_or0;cat=conve0;u1=https://oreoarabia.com/;u21=' +
          Global.language +
          ";u23=" +
          Global.country +
          ';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>';

        if (parseData.coupon == "Invalid") {
          if (Global.language == "english") {
            document.querySelector(".err").innerHTML = "Invalid Coupon Code";
          } else {
            document.querySelector(".err").innerHTML = "رقم قسيمه غير صالح";
          }
          document.querySelector(".err").style.opacity = 1;
        } else {
          document.querySelector(".err").style.opacity = 0;
          goToCongratsPage();
        }
      },
      null,
      {
        saveType: "formData",
        uniqID: Global.U_ID,
        country: Global.country,
        name: nameVal,
        email: emailVal,
        mobile: newMobile,
        uniqueCode: UniqueCodeVal,
        utm_term: utm_term,
        utm_medium: utm_medium,
        utm_source: utm_source,
        utm_content: utm_content,
        hubspotutk: window.htk,
        ga_cid: ga_cid,
        tc: Global.checkBox2,
      },
      "POST",
      null,
      false
    );
  }
}

function getCookie(cookie) {
  // console.log("getCookie");
  return document.cookie.split(";").reduce(function (prev, c) {
    var arr = c.split("=");
    return arr[0].trim() === cookie ? arr[1] : prev;
  }, undefined);
}
function getCookie2(cname) {
  // console.log("getCookie2");
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  // console.log(name);
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

/* Proceeed Handler */
function proceedBtnHandler() {
  // document.getElementById("ClickMUsic").play();
  Global.serverObj.send(
    Global.WEBSERVICE,
    {},
    null,
    {
      saveType: "saveCountry",
      uniqID: Global.U_ID,
      country: Global.country,
    },
    "POST",
    null,
    false
  );

  // document.querySelector(".firstPageDiv").style.display = "none";
  /*  if (Global.checkBox1 === false) {
    document.querySelector(".firstPageErr").style.opacity = "1";
  } else {
    document.querySelector(".firstPageDiv").style.display = "none";
    document.querySelector(".SecondPageDiv").style.display = "block";
  } */
  if (Global.country == "" || Global.country == null) {
    document.querySelector(".firstPageErr").style.opacity = "1";
  } else {
    document.querySelector(".firstPageDiv").style.display = "none";
    document.querySelector(".SecondPageDiv").style.display = "block";
  }
}

function goToCongratsPage() {
  document.querySelector(".SecondPageDiv").style.display = "none";
  document.querySelector(".thirdPageDiv").style.display = "block";
}

var firstPageTimeInterval;
function triggerFirstPageAnimation() {
  let xx = 0;
  let winCnt = 0;
  firstPageTimeInterval = setInterval(() => {
    xx += 1;
    if (xx > 11) xx = 1;
    document.querySelector(
      ".firstPageOreoAnim"
    ).src = `https://www.newsfeedsmartapp.com/Oreo_IPL/assets/oreo/${Global.language}/${xx}.png`;

    winCnt += 1;
    if (winCnt > 61) winCnt = 37;
    if (winCnt < 37) {
      document.querySelector(
        ".WinlogoAnim"
      ).src = `https://www.newsfeedsmartapp.com/Oreo_IPL/assets/win/${Global.language}/${winCnt}.png`;
    } else {
      document.querySelector(".glowImg").style.display = "block";
      document.querySelector(
        ".glowImg"
      ).src = `https://www.newsfeedsmartapp.com/Oreo_IPL/assets/win/glow/${winCnt}.png`;
    }
    // console.log(winCnt, "winCnt");
  }, 150);
}

function triggerThirdPageAnimation() {
  let orCnt = 0;

  setInterval(() => {
    orCnt += 1;
    if (orCnt > 11) orCnt = 1;
    document.querySelector(
      ".thirdPageOreoAnim"
    ).src = `https://www.newsfeedsmartapp.com/Oreo_IPL/assets/oreo/${Global.language}/${orCnt}.png`;
  }, 150);
}
